<template>
    <Modal label="Hapus Pengguna">
        <div class="r-delete-info">
            <div class="r-mr-10 r-pt-4">
                <img :src="require(`@/assets/icons/toast/warning.svg`)" alt="">
            </div>
            <div class="r-deleted-data">
                <p>Apakah anda yakin menghapus player ini ?</p>
                <table class="r-delete-table">
                    <tr>
                        <td>Username</td>
                        <td>:</td>
                        <td>{{item.username}}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{{item.email}}</td>
                    </tr>
                    <tr>
                        <td>Nomor Telepon</td>
                        <td>:</td>
                        <td>{{item.no_ponsel}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <template #actionButton>
            <ButtonCustom
                :disabled="loading" type="secondary" size="sm" class="r-mlr-10"
                @click.prevent="resetData()"
            > Batal </ButtonCustom>
            
            <ButtonCustom
                :loading="loading"
                type="danger" size="sm" class="r-mlr-10"
                @click.prevent="sendData()"
            >Hapus </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
export default {
    mixins: [common],
    components: {
        Modal
    },
    computed: {
        ...mapGetters({
            item: 'player/getItem',
        })
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        ...mapActions({
            delete: 'player/destroy'
        }),
        resetData() {
            this.$emit('resetData')
        },
        async sendData() {
            this.loading = true
            await this.delete(this.item)
            this.loading = false

            const response = this.getResponse('player')
            this.showSnackbar ({
                type: response.status == 1 ? 'success' : 'error',
                text: response.msg
            })

            if (response.status === 1) {
                this.resetData()
                this.$emit('getData')
            }
        }
    }
}
</script>