<template>
    <Modal label="Data Rekening">
        <InputCustom
            v-model="item.username"
            :error="error.username"
            :errorMsg="errorMsg.username"
            @onFocus="resetError('username')"
            placeholder="Username"
            label="Username"
            readonly
        >
        
        </InputCustom>

        <InputCustom
            :rightIcon="true"
            :type="showpassword == 'old' ? 'text' : 'password'"
            v-model="item.password"
            :error="error.password"
            :errorMsg="errorMsg.password"
            @onFocus="resetError('password')"
            placeholder="Password"
            label="Password"
        >
            <template #rightIcon>
                <div class="y-inputIcon r-center-flex">
                    <img :src="require(`@/assets/icons/login/${showpassword == 'old' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('old')">    
                </div>
            </template>
        </InputCustom>


        <InputCustom
            v-model="item.no_rek" 
            :error="error.no_rek"
            :errorMsg="errorMsg.no_rek"
            @onFocus="resetError('no_rek')"
            placeholder="Nomor Rekening"
            label="Nomor Rekening"
        >
        </InputCustom>

        <div class="y-inputField">
            <div class="y-labelText">
                Bank
            </div>
            <div class="y-inputPlace y-hasIcon-right">
                <div class="y-select-hasIcon" style="width: 100%">
                    <select class="y-select" v-model="item.id_bank">
                        <option class="y-option"
                            v-for="(l,i) in listBank" 
                            :key="`list-data-${i}`"
                            :value="l.id"
                        >
                            {{l.name}}
                        </option>
                    </select>
                    <div class="y-select-icon">
                        <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                    </div>
                </div>
            </div>
            <div
                class="y-errorText y-red-text"
                :style="error.id_bank ? 'opacity: 1 !important;': ''"
            >
                {{errorMsg.id_bank}}
            </div>
        </div>

        <InputCustom
            v-model="item.no_ponsel"
            :error="error.no_ponsel"
            :errorMsg="errorMsg.no_ponsel"
            @onFocus="resetError('no_ponsel')"
            placeholder="Nomor Ponsel"
            label="Nomor Ponsel"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.email"
            :error="error.email"
            :errorMsg="errorMsg.email"
            @onFocus="resetError('email')"
            placeholder="Email"
            label="Email"
        >
        
        </InputCustom>

        <template v-if="inputType == 'Simpan'">
            <div class="y-inputField">
                <div class="y-labelText">
                    Status
                </div>
                <div class="y-inputPlace y-hasIcon-right">
                    <div class="y-select-hasIcon" style="width: 100%">
                        <select class="y-select" v-model="item.status">
                            <option class="y-option" value="1">Aktif</option>
                            <option class="y-option" value="91">Suspend</option>
                        </select>
                        <div class="y-select-icon">
                            <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                        </div>
                    </div>
                </div>
                <div
                    class="y-errorText y-red-text"
                    :style="error.status ? 'opacity: 1 !important;': ''"
                >
                    {{errorMsg.status}}
                </div>
            </div>

            <div class="y-inputField">
                <div class="y-labelText">
                    Bandar Darat
                </div>
                <div class="y-inputPlace y-hasIcon-right">
                    <div class="y-select-hasIcon" style="width: 100%">
                        <select class="y-select" v-model="item.bd">
                            <option class="y-option" value="1">Yes</option>
                            <option class="y-option" value="0">No</option>
                        </select>
                        <div class="y-select-icon">
                            <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                        </div>
                    </div>
                </div>
                <div
                    class="y-errorText y-red-text"
                    :style="error.bd ? 'opacity: 1 !important;': ''"
                >
                    {{errorMsg.bd}}
                </div>
            </div>

            <InputCustom
                v-model="item.note"
                :error="error.note"
                :errorMsg="errorMsg.note"
                @onFocus="resetError('note')"
                placeholder="Note"
                label="Note"
            >
            
            </InputCustom>
        </template>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/Helper/Modal'
import validation from '@/mixins/function/validation'
export default {
    mixins: [
        common,
        validation
    ],
    components: {
        Modal,
    },
    async mounted() {
        this.loadBanks = true
        await this.getPlayerProfile(this.item)
        this.setData()
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            item: 'player/getItem',
            player: 'player/getPlayer',
            listBank: 'bank/getBanks'
        })
    },
    data: () => ({
        loadBanks: false,
        error: {
            name: null,
            id_bank: null,
            no_rek: null,
        },
        errorMsg: {
            name: 'Tidak boleh kosong',
            id_bank: 'Tidak boleh kosong',
            no_rek: 'Tidak boleh kosong',
        },
        loading: false,
        showpassword: false
    }),
    methods: {
        ...mapActions({
            store: 'player/store',
            setItem: 'player/setItem',
            update: 'player/update',
            getPlayerProfile: 'player/getProfilePlayer'
        }),
        resetData() {
            this.$emit('resetData')
        },
        validation() {
            var error = 0
            var field = ['name', 'id_bank', 'no_rek']
            for (var i = 0; i < field.length; i++){
                if(this.item[field[i]] === null || this.item[field[i]]  === ''){
                    this.error[field[i]] = true
                    error += 1;
                }else{
                    this.error[field[i]] = false
                }
            }
            if(this.item.no_rek !== null && this.item.no_rek !== ""){
                if(!(/^\d+$/.test(this.item.no_rek))){
                    this.error.no_rek = true
                    this.errorMsg.no_rek = "Hanya boleh angka"
                    error += 1;
                } else{
                    this.error.no_rek = false
                }
            }
            return error
        },
        async sendData() {
            if (this.validation() === 0) {
                this.loading = true
                await this.update(this.item)
                this.loading = false
                const response = this.getResponse('player')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        },
        setData() {
            this.setItem(this.player.data)
            this.item.password = this.player.password
        },
        setShowPassword(value){
            if(this.showpassword == value){
                this.showpassword = null
            }else{
                this.showpassword = value
            }
        },
    }
}
</script>