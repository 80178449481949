<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #created_at="props">
                {{convertDateTime(props.row.created_at)}}
            </template>

            <template #username="props">
                {{props.row.username}}
            </template>

            <template #rekening="props">
                {{props.row.name}} - {{props.row.nama_rek}} - {{props.row.no_rek}}
            </template>

            <template #email="props">
                {{props.row.email}}
            </template>
            
            <template #no_ponsel="props">
                {{props.row.no_ponsel}}
            </template>

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-green-text r-center-flex" @click.prevent="openVerifikasiModal(props.row)">Approve</div>
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="openEditModal(props.row)">Edit</div>
                        <div class="r-menu-item y-red-text r-center-flex" @click.prevent="openDeleteModal(props.row)">Delete</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <Verifikasi 
            v-if="modal == 'verifikasi'" 
            @resetData="resetData"
            @getData="getData"
        ></Verifikasi>

        <DeleteModal
            v-if="modal == 'Delete'" 
            @resetData="resetData"
            @getData="getData"
        >
        </DeleteModal>

        <UpdateModal
            v-if="modal == 'Edit'" 
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        >
        </UpdateModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import Verifikasi from '@/components/Pelanggan/Verifikasi'
import DeleteModal from '@/components/Pelanggan/DeleteModal'
import UpdateModal from '@/components/Pelanggan/UpdateModal'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        Verifikasi,
        DeleteModal,
        UpdateModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'player/getData',
            request: 'player/getRequest',
            item: 'player/getItem'
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Tanggal Daftar',
                isSortable: true,
                fieldName: 'created_at',
                class: ''
            },
            {
                label: 'Username',
                isSortable: true,
                fieldName: 'username',
                class: ''
            },
            {
                label: 'Rekening',
                isSortable: true,
                fieldName: 'rekening',
                class: ''
            },
            {
                label: 'Email',
                isSortable: false,
                fieldName: 'email',
                class: ''
            },
            {
                label: 'No Ponsel',
                isSortable: true,
                fieldName: 'no_ponsel',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
        statusLoading: null,
        status: 'Online',
    }),
    methods: {
        ...mapActions ({
            resetItem: 'player/resetItem',
            resetRequest: 'player/resetRequest',
            setItem: 'player/setItem',
            getPlayer: 'player/fetchDataReject',
            getAllBank: 'bank/getAllBank',
        }),
        setData(item) {
            item.name = item.player
            this.setItem(item)
            this.openModal('Ubah')
        },
        openVerifikasiModal(item) {
            this.setItem(item)
            this.modal = 'verifikasi'
        },
        openEditModal(item) {
            this.setItem(item)
            this.inputType = 'Edit',
            this.modal = 'Edit'
        },
        openDeleteModal(item) {
            this.setItem(item)
            this.modal = 'Delete'
        },
        resetData() {
            this.modal = null
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getPlayer()
            this.fetchData = false
            const response = this.getResponse('player')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [{
                name: 'Player Pending',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Pelanggan')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
            await this.getAllBank()
        },
    }
}
</script>